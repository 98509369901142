@font-face {
    font-family: 'icomoon';
    src:  url(../fonts/icomoon.eot?1cobi);
    src:  url(../fonts/icomoon.eot?1cobi#iefix) format('embedded-opentype'),
      url(../fonts/icomoon.ttf?1cobi) format('truetype'),
      url(../fonts/icomoon.woff?1cobi) format('woff'),
      url(../fonts/icomoon.svg?1cobi#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-algorithm:before {
    content: "\e915";
  }
  .icon-data:before {
    content: "\e916";
  }
  .icon-ideas:before {
    content: "\e917";
  }
  .icon-ui:before {
    content: "\e918";
  }
  .icon-google-play .path1:before {
    content: "\e911";
    color: rgb(109, 203, 210);
  }
  .icon-google-play .path2:before {
    content: "\e912";
    margin-left: -0.8876953125em;
    color: rgb(192, 225, 193);
  }
  .icon-google-play .path3:before {
    content: "\e913";
    margin-left: -0.8876953125em;
    color: rgb(220, 104, 161);
  }
  .icon-google-play .path4:before {
    content: "\e914";
    margin-left: -0.8876953125em;
    color: rgb(253, 202, 151);
  }
  .icon-apple-logo:before {
    content: "\e900";
  }
  .icon-arrow-down:before {
    content: "\e901";
  }
  .icon-arrow-left:before {
    content: "\e902";
  }
  .icon-arrow-light-down:before {
    content: "\e903";
  }
  .icon-arrow-light-left:before {
    content: "\e904";
  }
  .icon-arrow-light-right:before {
    content: "\e905";
  }
  .icon-arrow-light-up:before {
    content: "\e906";
  }
  .icon-arrow-right:before {
    content: "\e907";
  }
  .icon-arrow-up:before {
    content: "\e908";
  }
  .icon-at:before {
    content: "\e909";
  }
  .icon-check:before {
    content: "\e90a";
  }
  .icon-facebook-logo-button:before {
    content: "\e90b";
  }
  .icon-instagram-logo:before {
    content: "\e90c";
  }
  .icon-list:before {
    content: "\e90d";
  }
  .icon-placeholder:before {
    content: "\e90e";
  }
  .icon-smartphone:before {
    content: "\e90f";
  }
  .icon-youtube-logotype:before {
    content: "\e910";
  }
  .icon-location2:before {
    content: "\e948";
  }
  .icon-compass:before {
    content: "\e949";
  }
  .icon-search:before {
    content: "\e986";
  }
  .icon-zoom-in:before {
    content: "\e987";
  }
  .icon-zoom-out:before {
    content: "\e988";
  }
  .icon-eye:before {
    content: "\e9ce";
  }
  .icon-eye-blocked:before {
    content: "\e9d1";
  }
  .icon-plus:before {
    content: "\ea0a";
  }
  .icon-minus:before {
    content: "\ea0b";
  }
  .icon-cross:before {
    content: "\ea0f";
  }
  .icon-checkmark:before {
    content: "\ea10";
  }
  .icon-checkmark2:before {
    content: "\ea11";
  }
  .icon-facebook:before {
    content: "\ea90";
  }
  .icon-facebook2:before {
    content: "\ea91";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
  .icon-whatsapp:before {
    content: "\ea93";
  }
  .icon-spotify:before {
    content: "\ea94";
  }
  .icon-telegram:before {
    content: "\ea95";
  }
  .icon-twitter:before {
    content: "\ea96";
  }
  .icon-youtube:before {
    content: "\ea9d";
  }
  .icon-youtube2:before {
    content: "\ea9e";
  }
  .icon-tux:before {
    content: "\eabd";
  }
  .icon-appleinc:before {
    content: "\eabe";
  }
  .icon-android:before {
    content: "\eac0";
  }
  .icon-linkedin:before {
    content: "\eac9";
  }
  .icon-linkedin2:before {
    content: "\eaca";
  }
  .icon-pinterest:before {
    content: "\ead1";
  }
  .icon-pinterest2:before {
    content: "\ead2";
  }
  .icon-file-pdf:before {
    content: "\eadf";
  }
  .icon-file-openoffice:before {
    content: "\eae0";
  }
  .icon-file-word:before {
    content: "\eae1";
  }
  .icon-file-excel:before {
    content: "\eae2";
  }
  .icon-libreoffice:before {
    content: "\eae3";
  }
  