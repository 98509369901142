*,h1,h2,h3,h4,h5,h6,p,ul,a{ 
	margin: 0; padding: 0;
}

a, a:hover, a:focus, a:active, a.active{
    text-decoration: none;
}
img{
    max-width: 100%;
    height: auto;
}

ul, ol{
    list-style: none;
    padding: 0;
    margin: 0;
}

button{
    cursor: pointer;
}

:focus{
    outline: none!important;
}
input:focus{
    outline: none;
}