@charset 'utf-8';

/*!
 * Bootstrap v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "settings";
@import "mixins";
@import "root";
@import "print";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";

/*!
 * Project:	      2nr
 * Version:	      1.0
 * Assigned to:   Comup.pl
 */

//////// Vendor
@import "vendor/hamburgers/hamburgers";
@import "vendor/owl.carousel";
@import "vendor/swiper";
@import "vendor/animate";
//////// @import
@import "app/helpers/unit";
@import "app/reset";
@import "app/icons";

//////// Colours
$dark: #222121;
$white: #fff;
$blue: #5ce2ff;
$gray: #e5e5e5;
$gray-2: #f4f4f5;

/**! 01. Typography **/

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Roboto', sans-serif;
    color: $dark;
}

h1,
.h1 {
    font-size: 120px;
}

h2,
.h2 {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 50px;
}

h3,
.h3 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 50px;
}

h4,
.h4 {
    font-size: 26px;
}

h5,
.h5 {
    font-size: 20px;
}

h6,
.h6 {
    font-size: 18px;
}

p {
    font-size: 16px;
    font-weight: 300;
    color: $dark;
    margin-bottom: 20px;
}

strong {
    font-weight: 700;
}

.text-primary {
    color: $blue!important;
}

hr {
    margin: 30px 0px;
}

small,
.small {
    font-size: 14px;
    font-weight: 300;
}


/**! 02. Buttons **/

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    box-shadow: 0px 3px 0px #c0bbbb;
}

.btn {
    padding: 12px 30px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    min-width: 120px;
    outline: none!important;
}

.btn-primary {
    background-color: $gray-2;
    color: $dark;
    border-color: $gray-2;
}

button {
    -webkit-appearance: button;
    /* for input */
    -webkit-user-select: none;
    /* for button */
    -moz-user-select: none;
    -ms-user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.btn-primary:focus:not([disabled]):not(.disabled),
.btn-primary:active:not([disabled]):not(.disabled),
.btn-primary:hover:not([disabled]):not(.disabled),
.btn-primary:not(:disabled):not(.disabled):active:focus {
    background-color: $blue;
    color: $white;
    border-color: $blue;
    box-shadow: 0px 3px 0px #0c2b4f;
}

.arrow-round {
    background-color: $gray-2;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.25s;
    [class^="icon-"] {
        line-height: 1;
        font-size: 7px;
        color: $dark;
        transform-origin: center;
        transition: all 0.25s;
    }
    .icon-arrow-down {
        margin-left: 1px;
        margin-top: 2px;
    }
    .icon-arrow-right {
        margin-left: 3px;
    }
}

a:hover .arrow-round {
    background-color: $blue;
    [class^="icon-"] {
        color: $white;
    }
}

.btn-icon {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    [class^="icon"],
    img {
        line-height: 1;
        margin-right: 12px;
        font-size: 20px;
        position: relative;
        top: -1px;
    }
}

.btn-store {
    justify-content: center;
    min-width: 180px;
}

.dropdown-link {
    background-color: $blue;
    color: $white;
    border-color: $blue;
    box-shadow: 0px 3px 0px #0c2b4f;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    min-width: 55px;
    position: relative;
    outline: none!important;
    display: inline-block;
    color:#fff!important;
    .arrow-round {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        background-color: $blue;
        box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.45);
        [class^="icon"] {
            color: $white;
            transition: 0s;
        }
    }
}

.dropdown-link:after {
    content: none;
}

.dropdown-link-container {
    .dropdown-menu {
        min-width: 100%;
        background-color: $blue;
        padding: 0;
        border-radius: 5px;
    }
    .dropdown-item {
        padding: 8px 4px;
        text-align: center;
        color: $white;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: #1668c3;
        color: $white;
    }
}

.dropdown-link-container.show {
    .arrow-round [class^="icon"] {
        transform: rotate(180deg);
    }
}


.dropdown-style2{
    
    cursor: pointer;
    .dropdown-link{
        background-color: transparent;
        box-shadow: none;
        padding: 0px 8px;
        min-width: 45px;
    }

    .icon-arrow-down{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size:6px;
    }

    .dropdown-item{
        border-radius: 0px;
    }

    .dropdown-menu{
        border-radius: 0px;
        border:0px;
        margin-top: 0;
    }
}



.header.header-sticky{
    .dropdown-style2 .dropdown-link{
        color: $blue!important;
    }
}


.btn-emity{
    display: inline-block;
    position: relative;
    padding: 30px 0;
    padding-left: 25px;
    .home-emity{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .btn{
        padding-left: 80px;
    }
}


/**! 03. List **/

.list-check {
    margin-bottom: 40px;
    li {
        padding: 35px;
        padding-left: 70px;
        border-bottom: 1px solid $gray;
        position: relative;
        &:before {
            content: "\e90a";
            font-family: 'icomoon' !important;
            color: $blue;
            position: absolute;
            left: 0;
            top: 35px;
            font-size: 18px;
        }
    }
    li p {
        margin: 0;
    }
}

.list-dash {
    margin-bottom: 40px;
    li {
        padding: 35px;
        padding-left: 50px;
        position: relative;
        &:before {
            content: "-";
            position: absolute;
            left: 0;
            top: 32px;
            font-size: 18px;
            font-weight: 700;
        }
    }
    li p {
        margin: 0;
    }
}

.list-steps {
    margin-bottom: 40px;
    counter-reset: counter-decimal;
    li {
        padding: 20px;
        padding-left: 35px;
        position: relative;
        border-bottom: 1px solid $gray;
    }
    li p {
        margin: 0;
        font-size: 20px;
        font-weight: 300;
    }
    li:before {
        position: absolute;
        left: 0;
        top: 20px;
        content: counter(counter-decimal, decimal) ".";
        counter-increment: counter-decimal;
        font-size: 20px;
        font-weight: 300;
    }
}

.list-decimal {
    counter-reset: counter-decimal;
    margin: 20px 0px;
    li {
        position: relative;
        padding-left: 20px;
    }
    li p {
        margin: 0;
    }
    li:before {
        position: absolute;
        left: 0;
        top: 0;
        content: counter(counter-decimal, decimal) ".";
        counter-increment: counter-decimal;
    }
}

.list-law>li h5 {
    margin: 45px 0px;
    padding-left: 50px;
}

.list-law p {
    margin-bottom: 30px;
}

.list-law {
    counter-reset: counter-global;
}

.list-law>li {
    position: relative;
}

.list-law>li:before {
    content: "§ " counter(counter-global, decimal) ".";
    counter-increment: counter-global;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    left: 0;
    top: -3px;
}

.list-law>li>ol {
    counter-reset: counter-parent;
}

.list-law>li>ol>li {
    padding-left: 50px;
    position: relative;
}

.list-law>li>ol>li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: counter(counter-parent, decimal) ".";
    counter-increment: counter-parent;
}

.list-law>li>ol>li>ol {
    counter-reset: counter-child;
}

.list-law>li>ol>li>ol>li {
    position: relative;
}

.list-law>li>ol>li>ol>li:before {
    position: absolute;
    left: -50px;
    top: 0;
    content: counter(counter-parent, decimal) ".";
}

//faq
.list-faq {
    margin-bottom: 40px;
    margin-left: -40px;
    margin-right: -40px;
    .collapse-content {
        padding: 25px;
        padding-left: 40px;
        padding-top: 5px;
    }
    > li {
        border-bottom: 1px solid $gray;
    }
    
    h5 {
        font-weight: 300;
        padding: 25px;
        padding-left: 40px;
        position: relative;
    }
    
    a[data-toggle] {
        position: relative;
        display: block;
        h5 {
            color: $blue;
        }
        .collapse-icon {
            position: absolute;
            left: 0;
            top: 32px;
            font-size: 8px;
            color: $blue;
            [class^="icon-"] {
                transform: rotate(180deg);
                color: $blue;
                display: inline-block;
            }
        }
    }
    a[data-toggle].collapsed {
        h5 {
            color: $dark;
        }
        .collapse-icon {
            color: $dark;
            [class^="icon-"] {
                transform: rotate(0deg);
                color: $dark;
            }
        }
    }
}

.list-law>li>ol>li>ol>li:after {
    position: absolute;
    left: -35px;
    top: 0;
    content: counter(counter-child, decimal);
    counter-increment: counter-child;
}


/**! 04. layout **/

.page-content:not(.page-content-home) {
    padding: 60px 0px 120px 0px;
    overflow: hidden;
}



/**! 05. header, off-canvas **/

.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    padding: 45px 75px;
    background-color: transparent;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.0);
    transform: translate3d(0, 0, 0);
    transition: all 0.35s;
}

.header-row {
    display: flex;
    align-items: center;
}

.header-brand {
    margin-right: auto;
}

.header-brand {
    transition: all 0.35s;
    width: 145px;
    img{
        width: 145px;
        transition: all 0.35s;
    }
    .brand-white{
        display: block;
    }
    .brand-color{
        display: none;
    }
}

.header-lang{
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    li{
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        line-height: 1;
    }
    li:last-child{
        border:0px;
    }
    li a{
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        padding: 0 10px;
    }

    li a.active{
        color: #5ce2ff;
    }
}

.header {
    .hamburger {
        margin-left: 45px;
        line-height: 1;
    }
    .hamburger.is-active {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $dark;
        }
    }
}

.header.header-sticky {
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: $white;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);
    .header-brand {
        width: 145px;
        img{
            width: 52px;
        }
    }

    .header-brand {
        transition: all 0.35s;
        width: 92px;
        .brand-white{
            display: none;
        }
        .brand-color{
            display: block;
        }
    }

    .hamburger {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            //background-color: $dark;
        }
    }

    .header-lang{
        li{
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
        li:last-child{
            border:0px;
        }
        li a{
            color: #000;
        }
        li a.active{
            color: #5ce2ff;
        }
    }
}
.off-canvas-visable{
    .header-lang{
        li{
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
        li:last-child{
            border:0px;
        }
        li a{
            color: #000;
        }
        li a.active{
            color: #5ce2ff;
        }
    }
}

.page-hero-small {
    .hamburger {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $dark;
        }
    }
}

.off-canvas-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.3);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
}

.off-canvas-visable .off-canvas-bg {
    visibility: visible;
    opacity: 1;
}

.off-canvas {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    //justify-content: center;
    background-color: #fff;
    z-index: 99;
    width: 500px;
    max-width: 90%;
    transform: translate3d(100%, 0, 0);
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 40px 0px;
    padding-top: 160px;
}

.off-canvas.active {
    transform: translate3d(0%, 0, 0);
}

.nav-menu {
    display: block!important;
    text-align: center;
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.35s 0.65s;
    .nav-item {
        margin-bottom: 20px;
    }
    .nav-link {
        font-size: 30px;
        font-weight: 700;
        color: $dark;
    }
    .nav-link:hover,
    .nav-link.active {
        color: $blue;
    }
}

.off-canvas-socials {
    padding: 0 65px;
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.35s 0.65s;
    
    .nav {
        padding: 25px 0px;
        margin-bottom: 30px;
        border-bottom: 1px solid $gray-2;
        justify-content: center;
        .nav-link {
            font-size: 32px;
            padding: 0 5px;
            color: $dark;
        }
        .nav-link:hover {
            color: $blue;
        }
    }
}

.off-canvas-btn-group{
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.35s 0.65s;
}

.off-canvas.active{
    .nav-menu, .off-canvas-socials, .off-canvas-btn-group{
        opacity: 1;
        transform: translateY(0px);
    }
}


/**! 06. Page hero **/

.page-content {
    position: relative;
    z-index: 44;
    background-color: #fff;
}

.page-content-mask {
    position: relative;
    z-index: 44;
    img {
        display: block;
        width: 100%;
        position: relative;
        top: 2px;
    }
}

.page-hero {
    .hero-mask {
        position: fixed;
        transform: translate3d(0, 0, 0);
        left: 0;
        right: 0;
        top: 0;
        height: 33vw;
        .img-bg,
        .img-mask {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        .img-bg {
            background-size: cover;
            background-position: 50%;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.67);
            }
        }
    }
    .hero-home-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0%;
        padding-top: 22vw;
        z-index: 77;
        h2,
        h3 {
            color: #fff;
        }
        h2 {
            margin-bottom: 10px;
        }
        h3 {
            margin-bottom: 20px;
        }

        .btn{
            min-width: 160px;
            font-size: 13px;
            padding: 10px 28px;
        }
    }
    .video-mask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: -2px;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100vh;
            z-index: 22;
            background-color: rgba(0, 0, 0, 0.67);
        }
        video {
            display: block;
            position: relative;
            z-index: 20;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        .video-background{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            padding-bottom: 55.5%;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
        }
    }
}

.page-hero-home{
    position: relative;
    padding-top: 7vw;

    .hero-scroll{
        position: absolute;
        top: 36vw;
        z-index: 77;
        right: 0;
        transform: rotate(-90deg) translateX(-25%);
        a {
            display: flex;
            align-items: center;
            .arrow-round {
                margin-left: 10px;
            }
        }
    }
}


/**! 07. Forms **/

.form-control {
    display: block;
    width: 100%;
    padding: 20px 25px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #495057;
    background-color: $gray-2;
    border: 1px solid $gray-2;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    background-color: $gray-2;
    border: 1px solid $blue;
    box-shadow: none;
}

.form-row {
    margin-right: -10px;
    margin-left: -10px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.form-group {
    margin-bottom: 20px;
}

.form-control-danger {
    border-color: #ff0000;
}

.modal-dialog {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media(max-width: 768px) {
        min-height: 100vh;
    }
}


/**! 08. Footer **/

.app-footer {
    background-color: #fff;
    position: relative;
    z-index: 44;
}

.footer-image {
    display: block;
    width: 100%;
}

.footer-bg {
    background-color: #000000;
}

.footer-row {
    display: flex;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 60px;
}

.footer-brand {
    width: 117px;
    margin-right: 50px;
}

.footer {
    p,
    ul {
        margin: 0;
    }
    p,
    ul li a {
        color: #fff;
    }
}

.footer-social,
.footer-menu {
    display: flex;
    align-items: center;
}

.footer-copyright {
    margin-right: auto;
    a {
        color: #c8c7c7;
        margin-left: 10px;
        &:hover {
            color: #fff;
        }
    }
}

.nav-footer {
    .nav-link {
        font-weight: 700;
    }
}

ul.nav-social-footer {
    margin-left: 35px;
    .nav-link {
        padding: 0 5px;
        font-size: 32px;
        line-height: 1;
    }
}

/**! 09. Contact page **/

.contact-data {
    margin: 40px 0px;
    li {
        padding: 20px;
        border-top: 1px solid $gray;
        display: flex;
    }
    li:last-child {
        border-bottom: 1px solid $gray;
    }
}

.contact-item {
    position: relative;
    padding: 15px;
    padding-left: 60px;
    .contact-item-icon {
        position: absolute;
        left: 0;
        top: 0;
        color: $blue;
        font-size: 36px;
    }
    h6 {
        margin-bottom: 25px;
    }
    p {
        margin: 0;
    }
    a {
        color: inherit;
    }
}

.contact-item+.contact-item {
    margin-left: 30px;
}

.map-container {
    position: relative;
    padding-bottom: 32.5%;
    .map {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}



/**! 11. tutorials **/

ul.tutorial-menu{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    li{
        border-right: 1px solid #cccccc;
    }
    li:last-child{
        border:0px;
    }
    li  a{
        display: block;
        padding: 5px 60px;
        font-size: 24px;
        color: $dark;
        font-weight: 700;
    }
    li a.active{
        color: #5ce2ff;
    }
}

.tutorial-hero-text{
    ul.list-decimal{
        font-weight: 300;
        margin: 10px 0px 50px 0px;
    }

    h4{
        margin-bottom: 40px;
    }
}

ul.tutorial-list {
    margin-top: 110px;
    margin-bottom: 110px;
    >li {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: -15%;
            bottom: -15%;
            width: 28%;
            left: 0;
            background-image: url(../img/svg/tutorial-bg-left.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left center;
            z-index: -1;
        }
    }
}

.tutorial-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .tutorial-item-image {
        flex: 0 0 35%;
        max-width: 35%;
        order: 1;
    }
    .tutorial-item-content {
        flex: 0 0 40%;
        max-width: 40%;
        margin-left: 4%;
        order: 2;
    }

    .tutorial-item-image-on{
        flex: 0 0 100%;
        max-width: 100%;
        padding: 10% 0;


        .tooltip{
            min-width: 300px;
            max-width: 300px
        }
    }
}

ul.tutorial-list>li:nth-child(even):after {
    content: "";
    left: auto;
    right: 0;
    background-image: url(../img/svg/tutorial-bg-right.svg);
    background-position: right center;
}

ul.tutorial-list li:nth-child(even) {
    .tutorial-item {
        justify-content: flex-end;
        .tutorial-item-image {
            order: 2;
        }
        .tutorial-item-content {
            margin-right: 4%;
            margin-left: 0;
            order: 1;
        }
    }
}

.tutorial-tooltip{
    display: inline-block;
    position: relative;
    .tooltip-item{
        position: absolute;
        p{
            margin: 0;
            font-size: 14px;
            font-weight: 300;
        }
    }

    .tooltip{
        min-width: 170px;
        max-width: 300px
    }

    .tooltip.show{
        opacity: 1;
        
    }

    .tooltip-inner{
        box-shadow: -1px -1px 14px rgba(0, 0, 0, 0.1), 1px 1px 14px rgba(0, 0, 0, 0.1);
    }

    .bs-tooltip-top .arrow::before{
        border-top-color: #fff;
    }

    .tooltip-arrow-top-left .arrow{
        left: 20px;
    }

    .tooltip-arrow-top-right .arrow{
        left: auto;
        right: 20px;
    }

    .bs-tooltip-bottom .arrow::before{
        border-bottom-color: #fff;
    }
    
    .tooltip-arrow-bottom-right .arrow{
        right: 20px;
        left: auto;
    }

    .tooltip-arrow-bottom-left .arrow{
        left: 20px;
    }

    .tooltip-arrow-top-center .arrow{
        left: 50%;
    }

    .tooltip-arrow-bottom-right .arrow::before, 
    .tooltip-arrow-bottom-left .arrow::before{
        border-width: 0.8rem 0.4rem 0;
    }

    .tooltip-arrow-top-center .arrow::before{
        border-width: 0 0.4rem 0.8rem;
        margin-left: -0.2rem;
    }

    .tooltip-arrow-left-bottom .arrow{
        top: auto;
        bottom: 20px;
    }

    .tooltip-arrow-left-center .arrow{
        top: 45%;
    }

    .tooltip-arrow-right-center .arrow{
        top: 45%;
    }

    .tooltip-arrow-left-bottom .arrow:before{
        border-width: 0.4rem 0.8rem 0.4rem 0;
    }

    .bs-tooltip-left .arrow::before{
        border-left-color: #fff;
    }

    .tooltip-arrow-right-bottom .arrow{
        top: auto;
        bottom: 20px;
    }

    .tooltip-arrow-right-bottom .arrow:before{
        border-width: 0.4rem 0 0.4rem 0.8rem;
    }
}

.tutorial-tooltip .tooltip-item{
    opacity: 0;
}

.tutorial-tooltip .tooltip-arrow-bottom-right{
    transform: translate(0px, 50px);
}

.tutorial-tooltip .tooltip-arrow-bottom-left{
    transform: translate(0px, 50px);
}

.tutorial-tooltip .tooltip-arrow-top-center{
    transform: translate(0px, 50px);
}

.tutorial-tooltip .tooltip-arrow-left-bottom{
    transform: translate(50px, 0px);
}

.tutorial-tooltip .tooltip-arrow-top-left{
    transform: translate(0px, 50px);
}

.tutorial-tooltip .tooltip-arrow-right-bottom{
    transform: translate(-50px, 0px);
}

.tutorial-tooltip .tooltip-item:nth-of-type(1){
    transition: all 0.25s 0.8s;
}
.tutorial-tooltip .tooltip-item:nth-of-type(2){
    transition: all 0.25s 1.1s;
}
.tutorial-tooltip .tooltip-item:nth-of-type(3){
    transition: all 0.25s 1.4s;
}
.tutorial-tooltip .tooltip-item:nth-of-type(4){
    transition: all 0.25s 1.7s;
}
.tutorial-tooltip .tooltip-item:nth-of-type(5){
    transition: all 0.25s 2s;
}

.tutorial-item-image.wow-in .tutorial-tooltip .tooltip-item{
    opacity: 1;
    transform: translate(0px, 0px);
}


/**! 12. Home page **/

.section-spacer {
    margin: 20px 0px;
}

.section-bg-right,
.section-bg-left {
    position: relative;
    .section-image {
        position: absolute;
        top: 0;
        max-width: 36%;
        //z-index: -1;
        .section-image-mask {
            position: relative;
            overflow: hidden;
            z-index: 2;
        }
        .section-image-bg {
            position: absolute;
            z-index: 1;
            left: 1px;
            right: 1px;
            top: 1px;
            bottom: 1px;
            background-size: cover;
            background-color: #fff;
        }
    }
}

.section-bg-left {
    .section-image {
        left: 0;
    }
}

.section-bg-right {
    .section-image {
        right: 0;
    }
}
.phone-3-wifi{
    position: relative;
    .wifi{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 110%;
        width: 60%;
    }
}

.sim-video{
    video{
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

.section-image-voice{
    position: absolute;
    right: 10%;
    bottom: -10%;
    width: 60%;
    z-index: 888;
}

.section-2nr {
    margin-top: 150px;
    padding-bottom: 2vw;
    padding-top: 2vw;
}

.section-where {
    margin-top: 40px;
    padding-top: 2vw;
}

//phone group
.phone-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 40px;
    .phone-1,
    .phone-2 {
        position: relative;
        //max-width: 50%;
    }

    .wow-phone-1{
        position: relative;
        z-index: 44;
    }
    .wow-phone-2{
        position: relative;
        z-index: 22;
    }
    .phone-1 {
        z-index: 44;
    }
    .phone-2 {
        z-index: 22;
        transform: translateX(-40px);
    }
}

.phone-3 {
    margin-top: 25px;
}

//phone video
.phone-video {
    position: relative;
    overflow: hidden;
    display: inline-block;
    .phove-video-area {
        position: absolute;
        left: 4%;
        right: 3%;
        top: 13%;
        bottom: 12%;
        background-color: red;
        overflow: hidden;
        .video-phone-bg {
            position: absolute;
            left: 0%;
            right: 0%;
            top: 0%;
            bottom: 0%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: 50%;
        }
        .video-play {
            color: #fff;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            font-size: 44px;
            border: 4px solid #fff;
            background-color: rgba(255, 255, 255, 0.25);
            cursor: pointer;
            transition: all 0.3s;
            transform: scale(1) translate3d(0, 0, 0);
            [class^="icon"] {
                line-height: 1;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin-left: 5px;
            }
        }
        .video-play:hover {
            background-color: rgba(255, 255, 255, 0.25);
            transform: scale(1.1) translate3d(0, 0, 0);
        }
        video {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0; //width: 100%;
            //height: 100%;
            opacity: 0;
            visibility: hidden;
        }
    }
    .video-playing {
        video {
            opacity: 1;
            visibility: visible;
        }
    }
}


.phone-video-2{
    position: relative;
    display: inline-block;
    margin: 0 auto;

    video{
        max-width: 100%;
    }

    .video-phone-tutorial-bg {
        position: absolute;
        z-index: 44;
        left: 11%;
        right: 10.5%;
        top: 11.5%;
        bottom: 12%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.25);

    }

    .video-play {
        color: #fff;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        font-size: 44px;
        border: 4px solid #fff;
        background-color: rgba(255, 255, 255, 0.25);
        cursor: pointer;
        transition: all 0.3s;
        transform: scale(1) translate3d(0, 0, 0);
        transform-origin: center;
        [class^="icon"] {
            line-height: 1;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-left: 5px;
        }
    }
    .video-play:hover {
        background-color: rgba(255, 255, 255, 0.25);
        transform: scale(1.1) translate3d(0, 0, 0);
    }

    .video-phone-tutorial-bg.video-playing{
        visibility: hidden;
        opacity: 0;
    }
}
//parallax
.parallax {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45vh;
    padding: 18% 0 16% 0;
    .parallax-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50%;
        background-attachment: fixed;
        &:after {
            content: "";
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.67);
        }
    }
    .parallax-content {
        padding: 60px 40px;
        position: relative;
        z-index: 4;
        text-align: center;
        h2,
        h3 {
            color: $white;
        }
        h2 {
            text-transform: uppercase;
            margin-bottom: 10px;
            font-size: 80px;
        }
        h3 {
            margin: 0;
            font-size: 46px;
            color: #5ce2ff;
        }
    }

    .parallax-top-bg, .parallax-bottom-bg{
        position: absolute;
        z-index: 44;
        left: -5px;
        right: -5px;
        img{
            display: block;
            width: 100%;
        }
    }

    .parallax-top-bg{
        top: -2px;
    }
    .parallax-bottom-bg{
        bottom: -2px;
    }
}

// feature list
ul.list-feature {
    > li {
        margin-bottom: 20px;
    }
}

.feature-item {
    display: flex;
    h4 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 10px;
    }
    .feature-icon {
        flex: 0 0 190px;
    }
}

ul.list-feature-no-icons {
    > li {
        margin-bottom: 20px;
    }
    h4 {
        margin-bottom: 10px;
    }
}

// download-section
.download-section {
    margin: 50px 0;
    h4{
        margin-bottom: 10px;
    }
    text-align: center;
    .download-content {
        display: inline-flex;
        flex-direction: column;
        border: 3px solid $gray-2;
        padding: 55px;
        padding-bottom: 0;
        margin: 10px 0px 60px 0;
        margin-bottom: 45px;
        .group-btn {
            transform: translateY(50%);
        }
        p {
            max-width: 560px;
            margin-bottom: 0;
        }
    }
    p.small{
        margin-top: 25px;
    }
}

// section-home-tutorial
.section-home-tutorial{
    margin: 0;
    padding: 100px 0;
    h2{
        margin-bottom: 15px;
    }
    ul.list-decimal{
        margin: 10px 0px 45px 0px;
        font-weight: 300;
        padding-left: 45px;
    }
}

.tooltip-inner {
    max-width: 310px;
    padding: 20px 12px;
    color: #fff;
    text-align: center;
    background-color: #fff;
    border-radius: 0.25rem;
    text-align: left;
    box-shadow: -1px -1px 14px rgba(0, 0, 0, 0.1), 1px 1px 14px rgba(0, 0, 0, 0.1);

    h2{
        font-size: 16px;
        font-weight: 400;
        color: $blue;
        margin-bottom: 10px;
    }

    p{
        font-size: 16px;
        line-height: 18px;
    }
}

// section simplify
.simplify-container{
    max-width: 1920px;
    margin: 50px auto;
}
.simplify-row-icons{
    display: flex;
    
    .col-icons-left{
        flex: 0 0 40%;
        padding-left: 15%;
    }
    .col-icons-right{
        flex: 0 0 50%;
        padding-left: 25%;
    }
    .item-icon{
        padding: 50px 0;
        position: relative;
        padding-left: 120px;
        h4{
            margin-bottom: 20px;
            font-weight: 700;
        }
    }
    .it-icon{
        position: absolute;
        font-size: 84px;
        color: $blue;
        left: 0;
        top: 0;
    }
}
.simplify-row-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .col-center{
        text-align: center;
        flex: 0 1 30%;
        h2{
            margin-bottom: 10px;
            //white-space: nowrap;
        }
    }
    .col-left, .col-right{
        flex: 0 1 15%;
        text-align: center;
        position: relative;
        z-index: 2;
    }

    .col-right{
        transform: translateX(-20%);
    }

    .col-divider-left, .col-divider-right{
        flex: 0 1 20%;
        height: 380px;
        position: relative;
        z-index: 4;
        .divider{
            width: 100%;
            height: 1px;
            background-color: #5ce2ff;
            position: absolute;
            top: 130px;
            &:before, &:after{
                content: "";
                position: absolute;
                bottom: 0;
                width: 1px;
                background-color: #5ce2ff;
            }
            &:before{
                bottom: 0;
                height: 155px;
            }
            &:after{
                top: 0;
                height: 220px;
            }
        }
    }
    .col-divider-left{
        .divider{
            &:before{
                left: 40%;
            }
            &:after{
                left: 70%;
            }
        }
    }
    .col-divider-right{
        .divider{
            &:before{
                left: 70%;
            }
            &:after{
                left: 40%;
            }
        }
    }
}

.tooltip.show{
    opacity: 1;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #fff;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before{
    border-left-color: #fff;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before{
    border-bottom-color: #fff;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before{
    border-top-color: #fff;
}

// wall section
.wall-section {
    position: relative;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .wall-video {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.85)
        }
        video {
            position: absolute;
            min-width: 100%;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .wall-gradient {
        position: absolute;
        left: 0;
        right: 0;
        top: -1px;
        bottom: -1px;
        background: linear-gradient(to bottom, #fff 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 80%, #fff 100%);
    }
    
}

// remeber section
.section-remember {
    transform: translateY(-150px);
}

.remember-content {
    text-align: center;
    max-width: 780px;
    margin: 0 auto;
    p {
        max-width: 520px;
        margin: 20px auto;
    }
}

// background number
.background-number-section {
    position: relative;
    min-height: 720px;
}

.background-number {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 52%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

// home slider
.home-slider {
    transform: translateY(-90%);
    opacity: 0;
}

.home-slider.show-slider{
    opacity: 1;
}

.home-slider-row {
    display: flex;
    align-items: flex-end;
    .slider-home-text {
        flex-grow: 1;
        max-width: 50%;
    }
    .slider-phone-wrapper {
        //flex: 0 0 503px;
    }

    
}

.slider-wraper-home{
    text-align: right;
}

.slider-phone-wrapper {
    padding: 35px 80px;
    display: inline-block;
    position: relative;
    z-index: 88;
    
    .home-slider-nav {
        position: absolute;
        bottom: 50px;
        font-size: 56px;
    }
    .home-slider-prev {
        left: 0;
    }
    .home-slider-next {
        right: 0;
    }
}

.slider-phone {
    position: relative;
    display: inline-block;
    max-width: 343px;
    .owl-phone {
        position: absolute;
        left: 6%;
        right: 6%;
        top: 13%;
        bottom: 14%;
    }
    .owl-carousel,
    .owl-stage-outer,
    .owl-stage,
    .owl-item {
        height: 100%;
    }
    .slide-item {
        height: 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -125px;
        display: flex;
        justify-content: center;
    }
    .owl-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $blue;
        margin: 0 10px;
        transform: scale(1);
        transition: all 0.25s;
    }
    .owl-dot.active {
        background-color: #104cac;
        transform: scale(1.1);
    }

    .slide-item{
        background-size: cover;
        background-position: 50%;
    }
}

ul.slider-home-list {
   transform: translateY(-15%);
   position: relative;
    li {
        height: 0px;
        visibility: hidden;
        overflow: hidden;
    }
    li.active {
       height: auto;
       visibility: visible;
    }

    .slider-home-list-content{
        opacity: 0;
        transform: translateY(100px);
        visibility: hidden;
        transition: all 0.3s 0.5s;
    }

    li.active .slider-home-list-content{
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
    }
    
    li h3, li h2{
        color: #fff;
    }

    li h2{
        text-transform: uppercase;
        font-size: 80px;
        margin-bottom: 10px;
    }

    li h3{
        font-size: 38px;
        font-weight: 300;
        text-transform: none;
        margin-bottom: 20px;
        color: $blue;
    }
    
    li p {
        font-size: 16px;
        color: transparent;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        transition: all 0.25s;
        font-weight: 700;
        margin: 0;
        transform: translate3d(0, 0, 0);
        cursor: pointer;
    }
    li.active p {
        font-size: 24px;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0);
        color: $dark;
    }
    li p:hover:not(.active) {
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
}


.swiper-container{
    height: 180px!important;
    p{
        color: transparent;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
        transition: all 0.25s;
        font-weight: 700;
        margin: 0;
        transform: translate3d(0, 0, 0);
        margin: 0;
        line-height: 1;
        
    }
    .swiper-slide{}

    .swiper-slide-active p{
        font-size: 30px;
        text-shadow: 0 0 5px transparent;
        color: #222121;
    }
}

@media screen and (max-width: 1600px) {
    /**! 01. Typography **/
    h1,
    .h1 {
        font-size: 90px;
    }
    h2,
    .h2 {
        font-size: 48px;
    }
    h3,
    .h3 {
        font-size: 26px;
        margin-bottom: 40px;
    }
    h4,
    .h4 {
        font-size: 24px;
    }
    h5,
    .h5 {
        font-size: 20px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }


    /**! 05. header, off-canvas **/

    .header{
        padding-top: 10px;
    }

    .header-brand{
        width: 62px;
    }

    .off-canvas{
        padding-top: 80px;
    }
    .nav-menu .nav-link{
        font-size: 24px;
    }

    .nav-menu .nav-item{
        margin-bottom: 0;
    }

    /**! 12. Home page **/
    .slider-phone-wrapper{
        max-width: 460px;
    }
    
    ul.slider-home-list li h2{
        font-size: 48px;
    }

    ul.slider-home-list li h3{
        font-size: 30px;
    }
    
    

    ul.slider-home-list li.active p{
        font-size: 16px;
    }

    
    .feature-item .feature-icon {
        flex: 0 0 120px;
        margin-right: 20px;
    }

    .phone-video-2 .video-phone-tutorial-bg .video-play{
        width: 80px;
        height: 80px;
        font-size: 36px;
    }


    .swiper-container{
        height: 180px!important;
        p{
            color: transparent;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            transition: all 0.25s;
            font-weight: 700;
            margin: 0;
            transform: translate3d(0, 0, 0);
            margin: 0;
            
        }
        .swiper-slide{}
    
        .swiper-slide-active p{
            font-size: 20px;
            text-shadow: 0 0 5px transparent;
            color: #222121;
        }
    }

    .home-slider{
        transform: translateY(-85%);
    }
    .section-2nr{
        margin-top: 200px;
    }

    .simplify-container{
        margin: 0 auto;
    }
    .simplify-row-icons{
        .item-icon{
            padding: 20px;
            padding-left: 80px;
        }
        .it-icon{
            font-size: 64px;
        }
    }

    .simplify-row-icons .col-icons-left{
        flex: 0 0 50%;
    }

    .simplify-row-icons .col-icons-right{
        padding-left: 15%;
        flex: 0 0 50%;
    }

    .section-where{
        margin-top: 10px;
    }

    .section-home-tutorial{
        padding-top: 0;
    }


    .btn-emity{
        .btn{
            font-size: 13px;
            padding: 12px 20px;
            padding-left: 65px;
        }
        .home-emity{
            width: 80px;
        }
    }
}


@include media-breakpoint-down(lg) {
    /**! 01. Typography **/
    h1,
    .h1 {
        font-size: 72px;
    }
    h2,
    .h2 {
        font-size: 40px;
    }
    h3,
    .h3 {
        font-size: 24px;
        margin-bottom: 40px;
    }
    h4,
    .h4 {
        font-size: 22px;
    }
    h5,
    .h5 {
        font-size: 20px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }

    /**! 04. layout **/
    .app-body{
        margin-top: 68px;
        overflow-x: hidden;
    }

    .page-content{
        padding: 60px 0;
    }

    /**! 05. header, off-canvas **/
    .header, .header.header-sticky {
        padding: 10px 0px;
        background-color: $white;
        box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);
        .header-brand {
            width: 42px;
            .brand-color{
                display: block;
            }
            .brand-white{
                display: none;
            }
        }
        .header-lang li a {
            color: #000;
        }
        .header-lang li {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
        .header-lang li:last-child{
            border:0px;
        }
        .hamburger {
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $dark;
            }
        }
    }

    .header .hamburger {
        margin-left: 25px;
    }

    .dropdown-style2 .dropdown-link{
        color: $blue!important;
    }
    
    /**! 06. Page hero **/
    .page-hero .hero-mask{
        top: 48px;
    }
    
    .page-hero .hero-home-content{
        padding-top: 10vw;
    }

    .page-hero .hero-home-content{
        h2{
            font-size: 30px;
        }

        h3{
            font-size: 24px;
        }
    }


    .page-hero {
        .video-mask {
            video {
                display: none;
            }
        }
    }

    .page-hero {
        .video-mask {
            .video-background {
                display: block;
            }
        }
    }


    /**! 08. Footer **/
    .footer-brand {
        width: 50px;
        margin-right: 40px;
    }

    ul.nav-social-footer {
        margin-left: 20px;
    }


    /**! 12. Home page **/
    .page-hero .hero-home-content{
        h2{
            font-size: 30px;
        }

        h3{
            font-size: 24px;
        }
    }
    .parallax .parallax-bg{
        background-attachment: scroll;
    }


    .animated {
		/*CSS transitions*/
		transition-property: none !important;
		/*CSS transforms*/
		transform: none !important;
		/*CSS animations*/
        animation: none !important;
        opacity: 1!important;
        visibility: visible!important;
    }
    
    .tutorial-tooltip{
        margin: 40px 50px;

    }

    .tutorial-tooltip .tooltip-item p{
        font-size: 11px;
        line-height: 12px;
    }


    ul.slider-home-list {
        li h2{
            font-size: 32px;
        }
    }

    // background number
    .background-number-section {
        position: relative;
        min-height: 420px;
    }


    .home-slider{
        transform: translateY(-90%);
    }

    ul.slider-home-list li h2{
        font-size: 32px;
    }

    ul.slider-home-list li h3{
        font-size: 26px;
    }

    /**! tutorial-menu **/
    ul.tutorial-menu{
        li a {
            display: block;
            padding: 5px 20px;
            font-size: 18px;
        }
    }


    .simplify-row-2 .col-center{
        h2{
            font-size: 26px;
        }
        h3{
            font-size: 22px;
        }
    }


    .parallax .parallax-content{
        h2{
            font-size: 48px;
        }
        h3{
            font-size: 36px;
        }
    }
}


@include media-breakpoint-down(md) {
    /**! 08. Footer **/
    .footer-row, .nav-footer{
        display: block;
        text-align: center;
    }

    .footer-row{
        padding: 30px 0;
    }

    ul.nav-footer{
        margin: 20px 0;
    }

    ul.nav-social-footer{
        margin-left: 0;
        justify-content: center;
    }

    .footer-brand{
        width: 100%;
        margin: 10px 0px;
        text-align: center;

        img{
            max-width: 50px;
        }
    }

    .footer-copyright{
         p a{
             display: block;;
         }
    }
    
    /**! 11. tutorials **/
    ul.tutorial-list{
        margin-top: 0px;
        margin-bottom: 40px;
    }
    .tutorial-item{
        display: block;

        .tutorial-item-content{
            margin: 40px 0px;
            max-width: 100%;
            width: 100%;
        }

        .tutorial-item-image{
            margin: 40px 0px;
            max-width: 100%;
            width: 100%;
            text-align: center;
        }
    }

    .tutorial-tooltip{
        margin: 30px 0px;
    }

    .tutorial-tooltip .tooltip{
        max-width: 140px;
        min-width: 140px;
    }
    .tooltip-inner{
        padding: 10px;
        font-size: 11px;
        max-width: 140px;
    }

    ul.tutorial-menu{
        display: block;
        margin: 0;
        margin-bottom: 25px;
        li {
            border-right: 0px;
            border-bottom: 1px solid #cccccc;
        }
        li  a{
            padding-left: 0;
        }
    }

    /**! 12. Home page **/
    .section-bg-right .section-image, .section-bg-left .section-image{
        display: none;
    }

    .feature-container{
        .phone-3{
            display: block;
            margin: 0 auto;
            margin-bottom: 40px;
            max-width: 80%;
        }
    }

    .section-where{
        margin-top: 0;
        padding-top: 0;
    }

    .home-slider{
        transform: translateY(0px);
        margin-bottom: 0px!important;
    }
    
    .background-number{
        display: none;
    }

    .page-hero .hero-home-content{
        position: relative;
        padding-top: 2vh;
        transform: translateY(30%);
    }

    .home-slider-row{
        display: block;
        text-align: center;
    }

    .home-slider-row .slider-home-text{
        max-width: 100%;
    }

    ul.slider-home-list li p{
        color: $dark;
        text-shadow: none;
    }

    ul.slider-home-list li p:hover:not(.active){
        text-shadow: none;
    }

    .slider-phone-wrapper {
        padding: 35px 35px;
    }

    .slider-phone-wrapper .home-slider-nav{
        font-size: 24px;
    }

    .slider-wraper-home{
        text-align: center;
    }

    ul.slider-home-list li p{
        text-align: center;
    }

    .slider-phone {
        .owl-phone {
            left: 6.3%;
            right: 5.9%;
            top: 14.8%;
            bottom: 14%;
        }
    }

    .phone-video-2{
        margin-bottom: 40px;
    }

    .sw-wrapper{
        display: none;
    }


    .tutorial-item-image .tutorial-tooltip .tooltip-item{
        opacity: 1;
        transform: translate(0px, 0px);
        visibility: visible;
    }

    

    // background number
    .background-number-section {
        position: relative;
        min-height: auto;
    }

    ul.slider-home-list li h3, ul.slider-home-list li h2{
        color: $dark;
    }

    ul.slider-home-list {
        text-align: center;
        li h2, li h3{
            text-align: center;
        }
    }

    .page-hero-home, .page-hero-home ~ .page-content-mask{
        display: none;
    }

    /**! tutorial **/
    .list-faq{
        margin: 10px 0;
    }
    .list-faq h5{
        padding-left: 28px;
    }


    .parallax .parallax-content{
        h2{
            font-size: 24px;
        }
        h3{
            font-size: 18px;
        }
    }


    .simplify-container{
        display: flex;
        flex-direction: column;
        .simplify-row-2{
            order: -1;
        }
    }

    .simplify-row-2 .col-left, .simplify-row-2 .col-right, .col-divider-left, .col-divider-right{
        display: none;
    }

    .simplify-row-2 .col-center{
        flex: 1 1 100%;
        border:2px solid $blue;
        padding: 10px;
        margin-bottom: 20px;
        h3{
            font-size: 18px;
        }
    }


    .simplify-row-icons .col-icons-left, .simplify-row-icons .col-icons-right{
        flex: 1 1 50%;
        padding-left: 10px;
    }

    .simplify-row-icons .it-icon{
        font-size: 44px;
    }

    .simplify-row-icons .item-icon{
        padding-top: 10px;
        padding-left: 60px;
    }


    .section-home-tutorial{
        padding: 40px 0;
    }

    .download-section{
        margin: 0;
    }
}


@include media-breakpoint-down(sm) {
    /**! 01. Typography **/
    h1,
    .h1 {
        font-size: 44px;
    }
    h2,
    .h2 {
        font-size: 36px;
        margin-bottom: 30px;
    }
    h3,
    .h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    h4,
    .h4 {
        font-size: 20px;
    }
    h5,
    .h5 {
        font-size: 18px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }

    p{
        font-size: 14px;
    }


    /**! 03. List **/
    .list-dash {
        margin-bottom: 25px;
        li {
            padding: 10px;
            padding-left: 50px;

            &:before {
                content: "-";
                top: 8px;
            }
        }
    }

    .list-check {
        margin-bottom: 20px;
        li {
            padding: 10px;
            padding-left: 50px;
            
            &:before {
                top: 12px;
                font-size: 16px;
            }
        }
        li p {
            margin: 0;
        }
    }

    /**! 05. header, off-canvas **/
    .nav-menu .nav-link {
        font-size: 18px;
    }

    .off-canvas{
        padding-top: 80px;
    }

    .off-canvas-socials .nav{
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .off-canvas-socials{
        padding: 0 20px;
    }

    .off-canvas .btn-store{
        margin-top: 15px;
    }



    /**! 09. Contact page **/
    .contact-data {
        margin: 40px 0px;
        li {
            display: block;
        }
    }

    .contact-item {
        position: relative;
        padding: 15px;
        padding-left: 50px;
        .contact-item-icon {
            font-size: 32px;
        }
    }

    .contact-item+.contact-item {
        margin-left: 0px;
    }

    .map-container {
        position: relative;
        padding-bottom: 72.5%;
    }

    /**! 12. Home page **/
    .feature-item .feature-icon {
        flex: 0 0 100px;
        margin-right: 20px;
    }
    .download-section .download-content .group-btn {
       transform: translateY(0%);
       margin-top: 25px;
       margin-bottom: 20px;
       
       
        .btn{
           margin-bottom: 15px;
        }
    }

    .phone-video{
        margin-bottom: 40px;
        max-width: 80%;
        margin-left: 10%;
    }

    .phone-group{
        margin-top: 60px;
    }

    .section-spacer {
        margin: 20px 0px;
    }

    .simplify-row-icons{
        flex-wrap: wrap;
    }

    .simplify-row-icons .col-icons-left, .simplify-row-icons .col-icons-right{
        flex: 1 1 100%;
        padding-left: 10px;
    }

    .download-section .download-content{
        padding: 10px;
        padding-top: 30px;
        margin-bottom: 0;
    }

    .group-marketplace{
        .btn{
            display: block;
            width: 100%;
            margin: 10px 0;
        }
    }

    .tolltip-device-1{
        top: -4%!important;
        left: 8%!important;
    }
    .item-device-1{
        .tutorial-item-content{
            display: none;
        }
        .tutorial-item-image{
            padding: 0!important;
            margin: 0!important;
        }
    }
    .tooltip-skill-7{
        left: 30%!important;
        top: 65%!important;
        .tooltip .arrow::before{
            content: none;
        }
    }
}


//fix home page hero background
@media screen and (max-width: 1199px) {
    .page-hero .video-mask .video-background{
        padding-bottom: 82.5%;
    }
}


@media screen and (max-width: 1199px) and (orientation: landscape) {
    .page-hero .video-mask .video-background{
        padding-bottom: 56.5%;
    }
}


@media screen and (max-width: 640px) {
    .page-hero .video-mask .video-background{
        padding-bottom: 125.5%;
    }
}


@media screen and (max-width: 640px) and (orientation: landscape) {
    .page-hero .video-mask .video-background{
        padding-bottom: 72.5%;
    }
}


